<script>
import axios from "axios"

export default {
  name: "MakeTool",
  props: {
    tool: {
      type: Object,
    },
    isOpen: {
      type: Boolean,
    }
  },
	data(){
		return {
			ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      yahooPrice: 0,
      atcosmePrice: 0,
      rakutenPrice: 0,
      yahooDatetime: 0,
      rakutenDatetime: 0,
      atcosmeDatetime: 0
		}
	},
  mounted(){
    this.setPriceByItemId(this.tool.item_id)
  },
	methods: {
    checkYahooURL(affiliateUrl) {
      return "yahoo" in affiliateUrl && affiliateUrl.yahoo !== "";
    },
    checkAtcosmeURL(affiliateUrl) {
      return "atcosme" in affiliateUrl && affiliateUrl.atcosme !== "";
    },
    checkRakutenURL(affiliateUrl) {
      return "rakuten" in affiliateUrl && affiliateUrl.rakuten !== "";
    },
		isAffiliateUrlBlank(affiliateUrls) {
      if (
        (!("yahoo" in affiliateUrls) || affiliateUrls.yahoo === "") &&
        (!("atcosme" in affiliateUrls) || affiliateUrls.atcosme === "") &&
        (!("rakuten" in affiliateUrls) || affiliateUrls.rakuten === "")
      ) {
        return true
      }
      else {
        return false
      }
		},
    setPriceByItemId(itemId) {
      axios
        .post("https://api.dev.yuni-cosme.com/v1/price/", {
          item_id: itemId
        })
        .then((res) => {
          this.yahooPrice = "yahoo" in res.data ? res.data.yahoo.price : 0
          this.yahooDatetime = new Date(res.data.yahoo.timestamp*1000).toLocaleString()
          this.atcosmePrice = "atcosme" in res.data ? res.data.atcosme.price : 0
          this.atcosmeDatetime = new Date(res.data.atcosme.timestamp*1000).toLocaleString()
          this.rakutenPrice = "rakuten" in res.data ? res.data.rakuten.price : 0
          this.rakutenDatetime = new Date(res.data.rakuten.timestamp*1000).toLocaleString()
        })
        .catch((err) => {
          console.log("MakeTool", err.code, err.message);
        })
    },
    modalClose(e) {
      if(
        e.target.className=='makeupTools_modal' ||
        e.target.className=='makeupTools_modal_close'
      ){
        this.$emit('modalClose', false)
      }
    }
	}
};
</script>

<template>
  <section
    v-if="isOpen"
    class="makeupTools_modal"
    @click="modalClose($event)"
  >
    <div class="makeupTools_modal_container">
      <div
        class="makeupTools_modal_inner"
        @click="modalClose($event)"
      >
        <button
          class="makeupTools_modal_close"
        >
          <div class="makeupTools_modal_close_container">
            <span class="makeupTools_modal_close_border_top" />
            <span class="makeupTools_modal_close_border_bottom" />
          </div>
        </button>
        <img
          class="item_img"
          :src="
            ROOT_IMAGE_URL
              + '/makeup-tool/'
              + tool.item_id
              + '.jpg'
          "
        >
        <div class="item_brand">
          {{ tool.brand_name }}
        </div>
        <h2 class="item_name">
          {{ tool.item_name }}
        </h2>
        <div
          v-if="!isAffiliateUrlBlank(tool.affiliate_links)"
          class="item_purchase"
        >
          <div v-if="checkRakutenURL(tool.affiliate_links)" class="rakuten">
            <div class="first-line">
            <img src="@/assets/logos/rakuten_logo.svg">
            <div v-if="rakutenPrice === 0">
              <span>&nbsp;-&nbsp;</span>
            </div>
            <div v-else>
              <span>&nbsp;¥{{ rakutenPrice.toLocaleString() }}&nbsp;</span>
            </div>
            <a
              class="purchase_btn"
              :href="`${tool.affiliate_links.rakuten}`"
              rel="nofollow"
            >
              {{ $t('itemsLowestPrice') }}
            </a>
            </div>
            <div class="second-line">
              <p>{{rakutenDatetime}}{{ $t('itemsUpdate') }}</p>
            </div>
          </div>
          <div v-if="checkAtcosmeURL(tool.affiliate_links)" class="atcosme">
            <div class="first-line">
            <img src="@/assets/logos/atcosme.svg">
            <div v-if="atcosmePrice === 0">
              <span>&nbsp;-&nbsp;</span>
            </div>
            <div v-else>
              <span>&nbsp;¥{{ atcosmePrice.toLocaleString() }}&nbsp;</span>
            </div>
            <a
              class="purchase_btn"
              :href="`${tool.affiliate_links.atcosme}`"
              rel="nofollow"
            >
              {{ $t('itemsLowestPrice') }}
            </a>
            </div>
            <div class="second-line">
              <p>{{atcosmeDatetime}}{{ $t('itemsUpdate') }}</p>
            </div>
          </div>
          <div v-if="checkYahooURL(tool.affiliate_links)" class="yahoo">
            <div class="first-line">
            <img src="@/assets/logos/yahoo.svg">
            <div v-if="yahooPrice === 0">
              <span>&nbsp;-&nbsp;</span>
            </div>
            <div v-else>
              <span>&nbsp;¥{{ yahooPrice.toLocaleString() }}&nbsp;</span>
            </div>
            <a
              class="purchase_btn"
              :href="`${tool.affiliate_links.yahoo}`"
              rel="nofollow"
            >
              {{ $t('itemsLowestPrice') }}
            </a>
            </div>
            <div class="second-line">
              <p>{{yahooDatetime}}{{ $t('itemsUpdate') }}</p>
            </div>
          </div>
          <span class="item_price_attention">
            {{ $t('itemsAttention') }}
          </span>
        </div>
        <div
          v-if="isAffiliateUrlBlank(tool.affiliate_links)"
          class="item_officialPage_btn"
        >
          <a
            :href="`${tool.official_url}`"
          >
            {{ $t('itemsOfficialUrl') }}
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.item_category {
  display: inline-block;
}
.item_img {
  width: 100%;
  object-fit: cover;
  display: block;
}
.item_brand{
  font-size: 14px;
  padding-bottom: 5px;
}
.item_name {
  font-size: 20px;
  padding-bottom: 12px;
  font-weight: bold;
}
.item_purchase {
  padding-bottom: 43px;
}
.atcosme,
.yahoo,
.rakuten{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 6px;
  align-self: center;
}
.atcosme img,
.yahoo img,
.rakuten img {
  width: 24px;
}
.atcosme span,
.yahoo span,
.rakuten span {
  font-size: 18px;
  font-weight: bold;
  color: #F29F8F;
  display: inline-block;
  text-align: right;
  width: 80px;
  margin-right: 10px;
}
.item_price {
  font-family: "Brandon Grotesque";
  font-size: 24px;
}
.purchase_btn {
  font-size: 12px;
  font-weight: bold;
  padding: 8px 12px;
  color: #fff;
  background-color: #f29f8f;
  display: inline-block;
}
.item_officialPage_btn{
  text-align: center;
  padding-bottom: 45px;
}
.item_officialPage_btn a{
  font-size: 16px;
  font-weight: bold;
  padding: 10px 18px;
  color: #fff;
  background-color: #f29f8f;
  display: inline-block;
}
.item_price_attention{
  display: block;
  padding-top: 3px;
  font-size: 12px;
}
/* メイク動画・メイク道具・商品詳細 */
.item_additionalInfo_container{
  background-color: #F5EAE7;
  padding: 0 20px;
}
.item_additionalInfo{
  padding: 30px 0;
}

.makeupTools_inner{
  display: flex;
  min-width: max-content;
}
.makeupTools{
  padding-top: 20px;
}

/* モーダル部分 */
.makeupTools_modal {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 100;
}
.makeupTools_modal_container {
  position: relative;
  background-color: #fff;
  padding: 0 40px;
}
.makeupTools_modal_inner{
  padding: 25px 0;
}
.makeupTools_modal_close {
  float: right;
  display: block;
  cursor: pointer;
  width: 80px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.makeupTools_modal_close_container {
  position: relative;
  width: 40px;
}
.makeupTools_modal_close_border_top {
  background-color: #777;
  display: block;
  height: 2px;
  right: -20px;
  width: 100%;
  position: absolute;
  top:-6px;
  transform: translateY(6px) rotate(-20deg);
}
.makeupTools_modal_close_border_bottom {
  background-color: #777;
  display: block;
  height: 2px;
  right: -20px;
  width: 100%;
  position: absolute;
  bottom: -8px;
  transform: translateY(-6px) rotate(20deg);
}
.modal_item_img{
  width: 100%;
  object-fit: cover;
  padding: 25px 0;
}
.grey_screen{
  position: relative;
  pointer-events: none;
}
.grey_screen::before{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color:rgba(0, 0, 0, 0.4);
  z-index: 100;
  right: 0px;
  pointer-events: none;
}
.button_disabled{
  pointer-events: none;
}
.item_comment_title {
  text-align: center;
}
.item_comment_title p {
  font-size: 17px;
}
.item_comment {
  padding-top: 30px;
  text-align: center;
}
.item_comment p{
  text-align: left;
}
.item_comment_btn {
  text-align: center;
}
.item_comment_btn p {
  display: inline-block;
  padding: 11px 17px;
  border: #f29f8f solid 1px;
}
.first-line {
  display: flex;
  align-items: center;
}

.second-line {
  font-size: 4px;
  margin-top: 2px;
}
</style>