module.exports = {
  skincare: {
    1: {
      201: {
        ja: "乾燥・脂性混合の敏感肌",
        en: "Dry and oily mixed sensitive skin",
        zh: "干燥・油性混合敏感肌",
      },
      202: {
        ja: "乾燥強めの敏感肌",
        en: "Dry sensitive skin",
        zh: "干燥敏感肌",
      },
      203: {
        ja: "アトピー性皮膚炎の素因がある敏感肌",
        en: "Sensitive skin with atopic dermatitis",
        zh: "有湿疹素因的敏感肌",
      },
      204: {
        ja: "スキンケアの見直しで改善する敏感肌",
        en: "Sensitive skin that can be improved by reviewing skin care",
        zh: "通过重新审视护肤改善的敏感肌",
      },
      205: {
        ja: "生活習慣の見直しで改善する敏感肌",
        en: "Sensitive skin that can be improved by reviewing lifestyle habits",
        zh: "通过重新审视生活习惯改善的敏感肌",
      },
      206: {
        ja: "生活スタイルが原因の乾燥肌優位の混合肌",
        en: "Dry skin dominant mixed skin due to lifestyle",
        zh: "由于生活方式而导致的干性皮肤优势混合皮肤",
      },
      207: {
        ja: "スキンケア方法の見直しで軽快する混合肌",
        en: "Mixed skin that can be improved by reviewing skin care methods",
        zh: "通过重新审视护肤方法改善的混合皮肤",
      },
      208: {
        ja: "原因がはっきりしない脂性肌",
        en: "Oily skin with no clear cause",
        zh: "没有明确原因的油性皮肤",
      },
      209: {
        ja: "アトピー性皮膚炎の素因がある乾燥肌",
        en: "Dry skin with atopic dermatitis",
        zh: "有湿疹素因的干性皮肤",
      },
      210: {
        ja: "生活習慣の見直しで改善する乾燥肌",
        en: "Dry skin that can be improved by reviewing lifestyle habits",
        zh: "通过重新审视生活习惯改善的干性皮肤",
      },
      211: {
        ja: "肌の保湿構造が軟弱な乾燥肌",
        en: "Dry skin with weak moisturizing structure",
        zh: "保湿结构薄弱的干性皮肤",
      },
      212: {
        ja: "乾燥傾向はあるものの、比較的健康な正常肌",
        en: "Normal skin that is relatively healthy although it tends to be dry",
        zh: "尽管干燥，但相对健康的正常皮肤",
      },
      213: {
        ja: "スキンケアと生活習慣の見直しで軽快する脂性肌",
        en: "Oily skin that can be improved by reviewing skin care and lifestyle habits",
        zh: "通过重新审视护肤和生活习惯改善的油性皮肤",
      },
      214: {
        ja: "生活習慣の改善で軽快する脂性肌",
        en: "Oily skin that can be improved by improving lifestyle habits",
        zh: "通过改善生活习惯改善的油性皮肤",
      },
      215: {
        ja: "スキンケアと生活習慣の見直しで軽快する混合肌",
        en: "Mixed skin that can be improved by reviewing skin care and lifestyle habits",
        zh: "通过重新审视护肤和生活习惯改善的混合皮肤",
      },
      216: {
        ja: "スキンケアの見直しで改善する脂性肌",
        en: "Oily skin that can be improved by reviewing skin care",
        zh: "通过重新审视护肤改善的油性皮肤",
      },
      217: {
        ja: "脂っぽさはあるものの、比較的健康な正常肌",
        en: "Normal skin that is relatively healthy although it tends to be oily",
        zh: "尽管有点油，但相对健康的正常皮肤",
      },
      218: {
        ja: "生活習慣の改善で軽快する混合肌",
        en: "Mixed skin that can be improved by improving lifestyle habits",
        zh: "通过改善生活习惯改善的混合皮肤",
      },
      219: {
        ja: "ホルモン関与が強い脂性肌",
        en: "Oily skin with strong hormone involvement",
        zh: "荷尔蒙参与度高的油性皮肤",
      },
      220: {
        ja: "アトピー性皮膚炎の素因がある敏感肌",
        en: "Sensitive skin with atopic dermatitis",
        zh: "有湿疹素因的敏感肌",
      },
      221: {
        ja: "スキンケア方法の見直しで改善する混合肌",
        en: "Mixed skin that can be improved by reviewing skin care methods",
        zh: "通过重新审视护肤方法改善的混合皮肤",
      },
      222: {
        ja: "原因がはっきりしない混合肌",
        en: "Mixed skin with no clear cause",
        zh: "没有明确原因的混合皮肤",
      },
      223: {
        ja: "スキンケアと生活習慣の見直しで改善する混合肌",
        en: "Mixed skin that can be improved by reviewing skin care and lifestyle habits",
        zh: "通过重新审视护肤和生活习惯改善的混合皮肤",
      },
      224: {
        ja: "生活習慣の見直しで軽快する混合肌",
        en: "Mixed skin that can be improved by reviewing lifestyle habits",
        zh: "通过重新审视生活习惯改善的混合皮肤",
      },
      225: {
        ja: "スキンケア方法の見直しで改善する混合肌",
        en: "Mixed skin that can be improved by reviewing skin care methods",
        zh: "通过重新审视护肤方法改善的混合皮肤",
      },
      226: {
        ja: "原因がはっきりしない混合肌",
        en: "Mixed skin with no clear cause",
        zh: "没有明确原因的混合皮肤",
      },
      227: {
        ja: "スキンケアと生活習慣の見直しで改善する脂性肌",
        en: "Oily skin that can be improved by reviewing skin care and lifestyle habits",
        zh: "通过重新审视护肤和生活习惯改善的油性皮肤",
      },
      228: {
        ja: "生活習慣の見直しで軽快する脂性肌",
        en: "Oily skin that can be improved by reviewing lifestyle habits",
        zh: "通过重新审视生活习惯改善的油性皮肤",
      },
      229: {
        ja: "スキンケア方法の見直しで改善する脂性肌",
        en: "Oily skin that can be improved by reviewing skin care methods",
        zh: "通过重新审视护肤方法改善的油性皮肤",
      },
      230: {
        ja: "問題の全くない正常肌",
        en: "Normal skin with no problems",
        zh: "没有问题的正常皮肤",
      },
    },
    4: {
      0: {
        ja: "女性",
        en: "woman",
        zh: "女士",
      },
      1: {
        ja: "男性",
        en: "man",
        zh: "男人",
      },
    },
    5: {
      0: {
        ja: "10代",
        en: "10's",
        zh: "10的",
      },
      1: {
        ja: "20~25歳",
        en: "20~25 years old",
        zh: "20~25岁",
      },
      2: {
        ja: "26~30歳",
        en: "26~30 years old",
        zh: "26~30岁",
      },
      3: {
        ja: "31~35歳",
        en: "31~35 years old",
        zh: "31~35岁",
      },
      4: {
        ja: "36~40歳",
        en: "36~40 years old",
        zh: "36~40岁",
      },
      5: {
        ja: "41歳以上",
        en: "over 41 years old",
        zh: "41岁以上",
      },
    },
    7: {
      0: "ナチュラルコスメ",
      1: "",
    },
    8: {
      0: {
        ja: "~1000円",
        en: "~1000 yen",
        zh: "~1000日元",
      },
      1: {
        ja: "~3000円",
        en: "~3000 yen",
        zh: "~3000日元",
      },
      2: {
        ja: "~5000円",
        en: "~5000 yen",
        zh: "~5000日元",
      },
      3: {
        ja: "価格気にしない",
        en: "I don't mind the price",
        zh: "我不介意价格",
      },
    },
    9: {
      0: {
        ja: "ニキビ",
        en: "acne",
        zh: "粉刺",
      },
      1: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    10: {
      0: {
        ja: "メイク(しっかり)",
        en: "Makeup (firm)",
        zh: "化妆(牢固)",
      },
      1: {
        ja: "メイク(普通)",
        en: "Makeup (normal)",
        zh: "化妆(正常)",
      },
      2: {
        ja: "メイク(薄め)",
        en: "Makeup (thin)",
        zh: "化妆(薄)",
      },
    },
    11: {
      0: {
        ja: "美肌",
        en: "Beautiful skin",
        zh: "美丽的皮肤",
      },
      1: {
        ja: "エイジングケア",
        en: "Aging care",
        zh: "抗衰老",
      },
      2: {
        ja: "毛穴",
        en: "Pores",
        zh: "毛孔",
      },
      3: {
        ja: "保湿",
        en: "Moisturizing",
        zh: "保湿",
      },
    },
    20: {
      0: {
        ja: "10代",
        en: "10's",
        zh: "10的",
      },
      1: {
        ja: "20~25歳",
        en: "20~25 years old",
        zh: "20~25岁",
      },
      2: {
        ja: "26~30歳",
        en: "26~30 years old",
        zh: "26~30岁",
      },
      3: {
        ja: "31~35歳",
        en: "31~35 years old",
        zh: "31~35岁",
      },
      4: {
        ja: "36~40歳",
        en: "36~40 years old",
        zh: "36~40岁",
      },
      5: {
        ja: "41歳以上",
        en: "over 41 years old",
        zh: "41岁以上",
      },
    },
    21: {
      0: {
        ja: "女性",
        en: "woman",
        zh: "女士",
      },
      1: {
        ja: "男性",
        en: "man",
        zh: "男人",
      },
    },
    22: {
      0: {
        ja: "クレンジング",
        en: "Cleansing",
        zh: "清洁",
      },
      1: {
        ja: "洗顔",
        en: "face wash",
        zh: "洗脸",
      },
      2: {
        ja: "基礎化粧品",
        en: "Basic cosmetics",
        zh: "基本化妆品",
      },
      3: {
        ja: "スペシャルケア",
        en: "Special care",
        zh: "特殊护理",
      },
    },
    23: {
      0: {
        ja: "敏感肌",
        en: "Sensitive skin",
        zh: "敏感肌",
      },
      1: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    24: {
      0: {
        ja: "低刺激なスキンケアで肌荒れしたくない",
        en: "I don't want to get rough skin with low-irritant skin care",
        zh: "我不想用低刺激的护肤品弄坏皮肤",
      },
      1: {
        ja: "多少刺激があっても悩みにアプローチしたい",
        en: "I want to approach my worries even if there is some irritation",
        zh: "我想即使有些刺激也要解决我的烦恼",
      },
    },
    25: {
      0: {
        ja: "シミ",
        en: "Stain",
        zh: "污点",
      },
      1: {
        ja: "シワ",
        en: "Wrinkles",
        zh: "皱纹",
      },
      2: {
        ja: "毛穴",
        en: "Pores",
        zh: "毛孔",
      },
      3: {
        ja: "肌あれ",
        en: "Skin",
        zh: "皮肤",
      },

      4: {
        ja: "脂性肌",
        en: "Oily skin",
        zh: "油性皮肤",
      },
      5: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    26: {
      0: {
        ja: "ニキビ",
        en: "acne",
        zh: "粉刺",
      },
      1: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    27: {
      0: {
        ja: "乾燥：気になる",
        en: "Dry: I care",
        zh: "干燥：我在乎",
      },
      1: {
        ja: "乾燥：気にならない",
        en: "Dry: I don't care",
        zh: "干燥：我不在乎",
      },
    },
    28: {
      0: {
        ja: "さっぱり",
        en: "refreshing",
        zh: "清爽",
      },
      1: {
        ja: "しっとり",
        en: "moist",
        zh: "湿润",
      },
      2: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    30: {
      0: {
        ja: "敏感肌",
        en: "Sensitive skin",
        zh: "敏感肌",
      },
      1: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    31: {
      0: {
        ja: "毛穴",
        en: "Pores",
        zh: "毛孔",
      },
      1: {
        ja: "肌あれ",
        en: "Skin",
        zh: "皮肤",
      },
      2: {
        ja: "脂性肌",
        en: "Oily skin",
        zh: "油性皮肤",
      },
      3: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    32: {
      0: {
        ja: "ニキビ",
        en: "acne",
        zh: "粉刺",
      },
      1: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    33: {
      0: {
        ja: "乾燥：気になる",
        en: "Dry: I care",
        zh: "干燥：我在乎",
      },
      1: {
        ja: "乾燥：気にならない",
        en: "Dry: I don't care",
        zh: "干燥：我不在乎",
      },
    },
    34: {
      0: {
        ja: "さっぱり",
        en: "refreshing",
        zh: "清爽",
      },
      1: {
        ja: "しっとり",
        en: "moist",
        zh: "湿润",
      },
      2: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    35: {
      0: {
        ja: "崩れ防止系の下地",
        en: "Foundation primer",
        zh: "粉底打底",
      },
      1: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    36: {
      0: {
        ja: "メイク(薄め)",
        en: "Makeup (thin)",
        zh: "化妆(薄)",
      },
      1: {
        ja: "メイク(普通)",
        en: "Makeup (normal)",
        zh: "化妆(正常)",
      },
      2: {
        ja: "メイク(しっかり)",
        en: "Makeup (firm)",
        zh: "化妆(牢固)",
      },
    },
    40: {
      0: {
        ja: "敏感肌",
        en: "Sensitive skin",
        zh: "敏感肌",
      },
      1: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    41: {
      0: {
        ja: "毛穴",
        en: "Pores",
        zh: "毛孔",
      },
      1: {
        ja: "肌あれ",
        en: "Skin",
        zh: "皮肤",
      },
      2: {
        ja: "脂性肌",
        en: "Oily skin",
        zh: "油性皮肤",
      },
      3: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    42: {
      0: {
        ja: "ニキビ",
        en: "acne",
        zh: "粉刺",
      },
      1: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    43: {
      0: {
        ja: "乾燥：気になる",
        en: "Dry: I care",
        zh: "干燥：我在乎",
      },
      1: {
        ja: "乾燥：気にならない",
        en: "Dry: I don't care",
        zh: "干燥：我不在乎",
      },
    },
    44: {
      0: {
        ja: "さっぱり",
        en: "refreshing",
        zh: "清爽",
      },
      1: {
        ja: "しっとり",
        en: "moist",
        zh: "湿润",
      },
      2: {
        ja: "",
        en: "",
        zh: "",
      },
    },
    45: {
      0: {
        ja: "お湯洗顔で乾燥：感じる",
        en: "Dry with hot water: feel",
        zh: "热水洗脸干燥：感觉",
      },
      1: {
        ja: "お湯洗顔で乾燥：感じない",
        en: "Dry with hot water: don't feel",
        zh: "热水洗脸干燥：不感觉",
      },
    },
  },
  makeup: {
    1: {
      201: {
        ja: "乾燥・脂性混合の敏感肌",
        en: "Dry and oily mixed sensitive skin",
        zh: "干燥・油性混合敏感肌",
      },
      202: {
        ja: "乾燥強めの敏感肌",
        en: "Dry sensitive skin",
        zh: "干燥敏感肌",
      },
      203: {
        ja: "アトピー性皮膚炎の素因がある敏感肌",
        en: "Sensitive skin with atopic dermatitis",
        zh: "有湿疹素因的敏感肌",
      },
      204: {
        ja: "スキンケアの見直しで改善する敏感肌",
        en: "Sensitive skin that can be improved by reviewing skin care",
        zh: "通过重新审视护肤改善的敏感肌",
      },
      205: {
        ja: "生活習慣の見直しで改善する敏感肌",
        en: "Sensitive skin that can be improved by reviewing lifestyle habits",
        zh: "通过重新审视生活习惯改善的敏感肌",
      },
      206: {
        ja: "生活スタイルが原因の乾燥肌優位の混合肌",
        en: "Dry skin dominant mixed skin due to lifestyle",
        zh: "由于生活方式而导致的干性皮肤优势混合皮肤",
      },
      207: {
        ja: "スキンケア方法の見直しで軽快する混合肌",
        en: "Mixed skin that can be improved by reviewing skin care methods",
        zh: "通过重新审视护肤方法改善的混合皮肤",
      },
      208: {
        ja: "原因がはっきりしない脂性肌",
        en: "Oily skin with no clear cause",
        zh: "没有明确原因的油性皮肤",
      },
      209: {
        ja: "アトピー性皮膚炎の素因がある乾燥肌",
        en: "Dry skin with atopic dermatitis",
        zh: "有湿疹素因的干性皮肤",
      },
      210: {
        ja: "生活習慣の見直しで改善する乾燥肌",
        en: "Dry skin that can be improved by reviewing lifestyle habits",
        zh: "通过重新审视生活习惯改善的干性皮肤",
      },
      211: {
        ja: "肌の保湿構造が軟弱な乾燥肌",
        en: "Dry skin with weak moisturizing structure",
        zh: "保湿结构薄弱的干性皮肤",
      },
      212: {
        ja: "乾燥傾向はあるものの、比較的健康な正常肌",
        en: "Normal skin that is relatively healthy although it tends to be dry",
        zh: "尽管干燥，但相对健康的正常皮肤",
      },
      213: {
        ja: "スキンケアと生活習慣の見直しで軽快する脂性肌",
        en: "Oily skin that can be improved by reviewing skin care and lifestyle habits",
        zh: "通过重新审视护肤和生活习惯改善的油性皮肤",
      },
      214: {
        ja: "生活習慣の改善で軽快する脂性肌",
        en: "Oily skin that can be improved by improving lifestyle habits",
        zh: "通过改善生活习惯改善的油性皮肤",
      },
      215: {
        ja: "スキンケアと生活習慣の見直しで軽快する混合肌",
        en: "Mixed skin that can be improved by reviewing skin care and lifestyle habits",
        zh: "通过重新审视护肤和生活习惯改善的混合皮肤",
      },
      216: {
        ja: "スキンケアの見直しで改善する脂性肌",
        en: "Oily skin that can be improved by reviewing skin care",
        zh: "通过重新审视护肤改善的油性皮肤",
      },
      217: {
        ja: "脂っぽさはあるものの、比較的健康な正常肌",
        en: "Normal skin that is relatively healthy although it tends to be oily",
        zh: "尽管有点油，但相对健康的正常皮肤",
      },
      218: {
        ja: "生活習慣の改善で軽快する混合肌",
        en: "Mixed skin that can be improved by improving lifestyle habits",
        zh: "通过改善生活习惯改善的混合皮肤",
      },
      219: {
        ja: "ホルモン関与が強い脂性肌",
        en: "Oily skin with strong hormone involvement",
        zh: "荷尔蒙参与度高的油性皮肤",
      },
      220: {
        ja: "アトピー性皮膚炎の素因がある敏感肌",
        en: "Sensitive skin with atopic dermatitis",
        zh: "有湿疹素因的敏感肌",
      },
      221: {
        ja: "スキンケア方法の見直しで改善する混合肌",
        en: "Mixed skin that can be improved by reviewing skin care methods",
        zh: "通过重新审视护肤方法改善的混合皮肤",
      },
      222: {
        ja: "原因がはっきりしない混合肌",
        en: "Mixed skin with no clear cause",
        zh: "没有明确原因的混合皮肤",
      },
      223: {
        ja: "スキンケアと生活習慣の見直しで改善する混合肌",
        en: "Mixed skin that can be improved by reviewing skin care and lifestyle habits",
        zh: "通过重新审视护肤和生活习惯改善的混合皮肤",
      },
      224: {
        ja: "生活習慣の見直しで軽快する混合肌",
        en: "Mixed skin that can be improved by reviewing lifestyle habits",
        zh: "通过重新审视生活习惯改善的混合皮肤",
      },
      225: {
        ja: "スキンケア方法の見直しで改善する混合肌",
        en: "Mixed skin that can be improved by reviewing skin care methods",
        zh: "通过重新审视护肤方法改善的混合皮肤",
      },
      226: {
        ja: "原因がはっきりしない混合肌",
        en: "Mixed skin with no clear cause",
        zh: "没有明确原因的混合皮肤",
      },
      227: {
        ja: "スキンケアと生活習慣の見直しで改善する脂性肌",
        en: "Oily skin that can be improved by reviewing skin care and lifestyle habits",
        zh: "通过重新审视护肤和生活习惯改善的油性皮肤",
      },
      228: {
        ja: "生活習慣の見直しで軽快する脂性肌",
        en: "Oily skin that can be improved by reviewing lifestyle habits",
        zh: "通过重新审视生活习惯改善的油性皮肤",
      },
      229: {
        ja: "スキンケア方法の見直しで改善する脂性肌",
        en: "Oily skin that can be improved by reviewing skin care methods",
        zh: "通过重新审视护肤方法改善的油性皮肤",
      },
      230: {
        ja: "問題の全くない正常肌",
        en: "Normal skin with no problems",
        zh: "没有问题的正常皮肤",
      },
    },
    50: {
      0: {
        ja: "健康肌",
        en: "Healthy skin",
        zh: "健康的皮肤",
      },
      1: {
        ja: "色むら",
        en: "Uneven color",
        zh: "不均匀的颜色",
      },
      2: {
        ja: "小ジワ",
        en: "Small wrinkles",
        zh: "小皱纹",
      },
      3: {
        ja: "毛穴",
        en: "Pores",
        zh: "毛孔",
      },
    },
    51: {
      0: {
        ja: "ツヤ",
        en: "Glossy",
        zh: "打造光泽妆容",
      },
      1: {
        ja: "マット",
        en: "Matt",
        zh: "哑光妆容",
      },
    },
    52: {
      0: {
        ja: "輪郭:卵型",
        en: "Contour: oval",
        zh: "轮廓：卵圆形",
      },
      1: {
        ja: "輪郭:丸型",
        en: "Contour: round",
        zh: "轮廓：圆形",
      },
      2: {
        ja: "輪郭:面長",
        en: "Contour: oblong",
        zh: "轮廓：长圆形",
      },
      3: {
        ja: "輪郭:ベース型",
        en: "Contour: diamond",
        zh: "轮廓：钻石形",
      },
      4: {
        ja: "輪郭:三角",
        en: "Contour: triangular",
        zh: "轮廓：三角形",
      },
      5: {
        ja: "輪郭:四角",
        en: "Contour: square",
        zh: "轮廓：方形",
      },
    },
    53: {
      0: {
        ja: "遠心+上重心",
        en: "centrifugal face + placement on top",
        zh: "离心面+放置在顶部",
      },
      1: {
        ja: "遠心+下重心",
        en: "centrifugal face + bottom placement",
        zh: "离心面+底部放置",
      },
      2: {
        ja: "求心+上重心",
        en: "centripetal face + placement on top",
        zh: "向心面+放置在顶部",
      },
      3: {
        ja: "求心+下重心",
        en: "centripetal face + bottom placement",
        zh: "向心面+底部放置",
      },
      4: {
        ja: "黄金比",
        en: "Golden ratio",
        zh: "黄金比例",
      },
    },
    54: {
      0: {
        ja: "おでこ:丸みがある",
        en: "Forehead: round",
        zh: "前额：圆形",
      },
      1: {
        ja: "おでこ:ふつう",
        en: "Forehead: normal",
        zh: "前额：正常",
      },
      2: {
        ja: "おでこ:平ら",
        en: "Forehead: flat",
        zh: "前额：平",
      },
    },
    55: {
      0: {
        ja: "彫り:深め",
        en: "Carving: deep",
        zh: "雕刻：深",
      },
      1: {
        ja: "彫り:浅め",
        en: "Carving: shallow",
        zh: "雕刻：浅",
      },
    },
    56: {
      0: {
        ja: "あご:出ている",
        en: "Chin: out",
        zh: "下巴：出",
      },
      1: {
        ja: "あご:ふつう",
        en: "Chin: normal",
        zh: "下巴：正常",
      },
      2: {
        ja: "あご:ない",
        en: "Chin: none",
        zh: "下巴：无",
      },
    },
    57: {
      0: {
        ja: "頬骨:気になる",
        en: "Cheekbones: I care",
        zh: "颧骨：我在乎",
      },
      1: {
        ja: "頬骨:気にならない",
        en: "Cheekbones: I don't care",
        zh: "颧骨：我不在乎",
      },
    },
  },
};
